import { Price, SurchargingRule, CheckoutItemPayment } from '@checkout/shared/src/model';

interface NotificationSurcharging {
  /** The amout of money that was surcharged */
  price: Price;
  /** The surchargint rule that was used */
  rule: SurchargingRule;
}

export interface CallbackBehaviour {
  status?: number;
  body?: unknown;
  delayMilliseconds?: number;
  disable3DS?: boolean;
}

export enum ShopNotificationType {
  THREEDS_CONFIRMATION = 'THREEDS_CONFIRMATION',
  PAYMENT_NOTIFICATION = 'PAYMENT_NOTIFICATION',
  CARDINAL_INITIALIZATION = 'CARDINAL_INITIALIZATION',
  EXPIRATION_NOTIFICATION = 'EXPIRATION_NOTIFICATION',
}

export interface PartialShopNotificationV2 {
  checkoutItemPayments: CheckoutItemPayment[];
  surcharging?: NotificationSurcharging;
}

import { Checkout } from '@checkout/shared/src/model';
export function getCheckoutUrl(checkoutId: string, locale: string): string;
export function getCheckoutUrl(checkout: Checkout): string;

export function getCheckoutUrl(checkoutOrCheckoutId: string | Checkout, locale?: string): string {
  if (typeof checkoutOrCheckoutId === 'object' && 'id' in checkoutOrCheckoutId && 'locale' in checkoutOrCheckoutId) {
    return `/${checkoutOrCheckoutId.locale}/${checkoutOrCheckoutId.id}/`;
  }
  return `/${locale}/${checkoutOrCheckoutId}/`;
}

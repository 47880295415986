import { isPresent } from '@checkout/shared/src/util';
import { createId as cuid } from '@paralleldrive/cuid2';
import Cookies from 'js-cookie';
import { checkoutChannelHeaderName } from '../../shared/src/util/http-client-constants';
import { getCheckoutChannel } from './current-app/utils/domUtils';
import { ENV } from './envVars';

export const cookieName = 'checkoutBrowserAccessToken';
const sessionIdName = 'client-sessionId';

const setCookie = (value: string) => {
  Cookies.set(cookieName, value, { expires: 1, secure: ENV.secureCookies });
};

export const browserHasAccessToken = () => isPresent(Cookies.get(cookieName));

const getAccessToken = (): string => {
  const secureString = Cookies.get(cookieName);

  if (secureString) {
    setCookie(secureString);
    return secureString;
  } else {
    const generatedSecureString = `accesstoken-${cuid()}`;
    setCookie(generatedSecureString);
    return generatedSecureString;
  }
};

const generateSessionId = (): string => {
  return String(Date.now());
};

const getSessionId = (): string | undefined => {
  if (!window.sessionStorage) return;

  const sessionId = window.sessionStorage.getItem(sessionIdName);
  if (sessionId) return sessionId;

  const generatedSessionId = generateSessionId();
  window.sessionStorage.setItem(sessionIdName, generatedSessionId);
  return generatedSessionId;
};

export type AuthorizationConfigObject = {
  headers: { Authorization: string; 'X-Session-Id'?: string; [checkoutChannelHeaderName]?: string };
};

export const getAxiosAuthorizationConfig = (): AuthorizationConfigObject => {
  const sessionId = getSessionId();
  const channel = getCheckoutChannel();
  return {
    headers: {
      Authorization: getAccessToken(),
      ...(sessionId ? { 'X-Session-Id': sessionId } : {}),
      ...(channel ? { [checkoutChannelHeaderName]: channel } : {}),
    },
  };
};

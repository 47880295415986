import { CheckoutChannel } from '@checkout/shared/src/model';
import { isPresent } from '@checkout/shared/src/util';
import endsWith from 'lodash/endsWith';
import throttle from 'lodash/throttle';

// Todo: Find out a better way to accomplish this, this is here to be able to
// access the Window in typescript
declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    ApplePaySession: any;
    webkit: any;
    nativeBridge: any;
    Android: any;
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }
}

export const disableBackForwardCaching = () => {
  // Safari does BackForward caching, lets disable that. This callback is called when Safari gets this state from history.
  const BFCacheBuster = () => {
    window.removeEventListener('pageshow', BFCacheBuster);
    window.location.reload();
  };

  // TODO: do this only for Safari
  window.addEventListener('pageshow', BFCacheBuster);
};

export const redirectBrowser = (url: string): void => {
  disableBackForwardCaching();
  window.location.href = url;
};

export const historyBack = () => window.history.back();

export const FALLBACK = { language: 'en' };

export const parseLocale = (path: string): string =>
  path.split('/').length < 3 ? FALLBACK.language : path.split('/').filter((val) => val)[0];

export const convertLocaleToIeTf = (locale: string): string => locale.replace('_', '-');
export const getClientLocale = (): string => parseLocale(window.location.pathname);
export const getBaseUrl = () => {
  const split = window.location.pathname.split('/');
  return [split[0], split[1], split[2]].join('/');
};

export const isRedirectSuccessPage = (url?: string): boolean => endsWith(url ?? window.location.pathname, '/success');
export const isRedirectFailPage = (url?: string): boolean => endsWith(url ?? window.location.pathname, '/fail');
export const isRedirectCancelPage = (url?: string): boolean => endsWith(url ?? window.location.pathname, '/cancel');
export const isRedirectReturnPage = (url?: string): boolean =>
  isRedirectSuccessPage(url) || isRedirectFailPage(url) || isRedirectCancelPage(url);
export const getClientLanguage = (): string => getClientLocale().split('_')[0];

export const isNativeFinnairIOSApp = (): boolean =>
  isPresent(window.webkit) && isPresent(window.webkit.messageHandlers.isNativeFinnairIosApp);

export const isNativeFinnairAndroidApp = (): boolean => isPresent(window.Android);

export const isFinnairApp = (): boolean => isNativeFinnairAndroidApp() || isNativeFinnairIOSApp();

export const getCheckoutChannel = (): CheckoutChannel => {
  if (isNativeFinnairAndroidApp()) return CheckoutChannel.ANDROID;
  if (isNativeFinnairIOSApp()) return CheckoutChannel.IOS;
  return CheckoutChannel.WEB;
};

export const scrollToTop = (offset?: number): void => {
  if (!window.scrollTo) return;
  window.scrollTo(0, offset || 0);
};

const DEFAULT_THROTTLE_TIMEOUT_MS = 500;

/**
 * This should be done only once per component lifecycle, i.e. in componentDidMount or constructor
 */
export const preventDoubleClick = <F extends (...args: unknown[]) => unknown>(callback: F, timeoutMs?: number): F =>
  throttle(callback, timeoutMs ?? DEFAULT_THROTTLE_TIMEOUT_MS, {
    // trailing: false will prevent the trailing event from firing.
    trailing: false,
  }) as unknown as F;

// @ts-expect-error IE uses lower case "documentMode"
const isIE11 = (): boolean => !!window.MSInputMethodContext && !!document.documentMode;

const containsOldEdgeUserAgent = (userAgent: string): boolean => {
  const oldEdgeVersions = [12, 13, 14, 15];
  return oldEdgeVersions.some((version) => userAgent.indexOf(`Edge/${version}`) !== -1);
};

const isOldEdge = (): boolean =>
  Boolean(window && window.navigator && window.navigator.userAgent && window.navigator.userAgent.indexOf) &&
  containsOldEdgeUserAgent(window.navigator.userAgent);

export const isNonSupportedBrowser = (): boolean => isIE11() || isOldEdge();

export const isBrowserUsingHttps = (): boolean =>
  Boolean(window && window.location && window.location.protocol === 'https:');

export const createApplePaySession = (
  version: number,
  applePayRequest: ApplePayJS.ApplePayPaymentRequest
): ApplePaySession => {
  return new ApplePaySession(version, applePayRequest);
};

export const getElementOffsetHeight = (selector: string): number => {
  const element: HTMLElement | null = document.querySelector(selector);
  return isPresent(element) ? element.offsetHeight : 0;
};

export const setAppScrollable = (scrollable: boolean) => {
  document.body.style.overflow = scrollable ? 'hidden' : '';
};

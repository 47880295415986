import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import has from 'lodash/has';
import { cmsBaseUrl, getTermsAndConditionsContents, getTexts, parseLocale, Texts } from '../../i18n';

const globalDisturbanceBannerUrlKey = 'fragments.globalDisturbanceBanner.url';

type ReplaceParams = Array<string | number>;

let cachedTranslations: Texts;

const replaceMsgFormat = (input: string, args: ReplaceParams = []): string => {
  if (typeof input !== 'string') return input;

  let result = input;
  args.forEach((value, key) => {
    const regex = new RegExp(`\\{${key}\\}`, 'g');
    result = result.replace(regex, String(value));
  });
  return result;
};

export function i18n(key: string, ...args: ReplaceParams): string {
  if (!cachedTranslations || !has(cachedTranslations, key)) return `${key}___TRANSLATION_MISSING`;
  return replaceMsgFormat(get(cachedTranslations, key) as string, args);
}

export const i18nSimple = (key: string, fallback: string) => get(cachedTranslations, key, fallback) as string;

export const getGlobalDisturbanceBannerUrl = (): string | undefined => {
  const bannerUrl = get(cachedTranslations, globalDisturbanceBannerUrlKey);
  if (bannerUrl) {
    return `${cmsBaseUrl}${bannerUrl}?view=fin-fragment-json&properties=items`;
  }
  return undefined;
};

export const getFragmentUrl = (fragmentName: string, properties: string[]): string | undefined => {
  const url = get(cachedTranslations, `fragments.${fragmentName}.url`);
  if (url) {
    return `${cmsBaseUrl}${url}?view=fin-fragment-json&properties=${properties.join(',')}`;
  }
  return undefined;
};

export const getTermsAndConditions = async (): Promise<{ title: string; content: string } | undefined> => {
  try {
    // TODO: Once content team is ready, we can define the terms and conditions by service
    const url = getFragmentUrl('termsAndConditions', ['detailText', 'teaserTitle']);
    if (url) {
      return await getTermsAndConditionsContents(url);
    } else {
      throw new Error(`Failed to load terms and conditions from ${url}`);
    }
  } catch (err) {
    let message = err;
    if (err instanceof Error) {
      message = err.message;
    }
    Sentry.captureException(`Failed to load terms and conditions content, ${message}`);
  }
  return undefined;
};

export const loadTranslations = async (locale: string) => {
  const [lang, country] = locale.toLowerCase().split('_');
  cachedTranslations = await getTexts(parseLocale(`${country}-${lang}`));
};

export const setTranslations = (translations: Texts) => {
  cachedTranslations = translations;
};

import { NotificationComponent, RedesignedNotificationData } from '@checkout/shared/src/model';
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';
import { returnToAppQueryParameterName } from '../../shared/src/util/http-client-constants';
import { fetchCheckout } from './api';
import { isFinnairApp } from './current-app/utils/domUtils';
import { ENV } from './envVars';
import { getGlobalDisturbanceBanners } from './i18n';
import { parseLocale } from './redesigned-app/utils/dom';
import { getGlobalDisturbanceBannerUrl, loadTranslations } from './redesigned-app/utils/translate';

const returnToFinnairAppPath = 'return-to-finnair-app';
const returnToFinnairAppRedesignPath = 'return-to-finnair-app-from-redesign';

const initClient = async () => {
  const [_baseUrl, _locale, checkoutId, page] = window.location.pathname.split('/');

  if (window.location.href.includes(returnToFinnairAppRedesignPath)) {
    const redesignedApp = await import(/* webpackChunkName: "redesigned-app" */ './redesigned-app');
    await loadTranslations(parseLocale(window.location.pathname));
    redesignedApp.renderReturnToFinnairApp();
    return;
  }

  if (window.location.href.includes(returnToFinnairAppPath)) {
    import(/* webpackChunkName: "current-app" */ './current-app');
  }

  if (page === '404') {
    // TODO: Remove this after current app can be removed
    // import(/* webpackChunkName: "current-app" */ './current-app');
    const redesignedApp = await import(/* webpackChunkName: "redesigned-app" */ './redesigned-app');
    await loadTranslations(parseLocale(window.location.pathname));
    redesignedApp.renderNotFound();
    return;
  }

  const queryParameters = new URLSearchParams(window.location.search);
  const returnToApp = queryParameters?.get(returnToAppQueryParameterName);
  const isRedesignedApp = queryParameters?.get('redesign') === 'true';
  if (returnToApp && !isFinnairApp()) {
    if (isRedesignedApp) {
      window.location.href = `${_baseUrl}/${_locale}/${checkoutId}/${returnToFinnairAppRedesignPath}?${returnToAppQueryParameterName}=${encodeURIComponent(returnToApp)}`;
    } else {
      window.location.href = `${_baseUrl}/${_locale}/${checkoutId}/${returnToFinnairAppPath}?${returnToAppQueryParameterName}=${encodeURIComponent(returnToApp)}`;
    }
    return;
  }

  const res = await fetchCheckout(checkoutId);

  if (res.success) {
    if (res.data.featureFlags?.DATADOG_RUM) initializeDatadogRum();
    if (res.data.redesignedCheckout) {
      const redesignedApp = await import(/* webpackChunkName: "redesigned-app" */ './redesigned-app');
      await loadTranslations(parseLocale(window.location.pathname));
      const url = getGlobalDisturbanceBannerUrl();
      const globalDisturbanceBanners = url ? await getGlobalDisturbanceBanners(url) : [];
      const redesignedGlobalDisturbanceBanners: RedesignedNotificationData[] = globalDisturbanceBanners.map(
        (banner) => ({ ...banner, component: NotificationComponent.Generic })
      );
      redesignedApp.render(res.data, redesignedGlobalDisturbanceBanners);
    } else {
      import(/* webpackChunkName: "current-app" */ './current-app');
    }
  } else {
    window.location.href = `${_baseUrl}/${_locale}/${checkoutId}/404`;
  }
};

initClient();

const initializeDatadogRum = () => {
  datadogRum.init({
    applicationId: '78e4984f-68ca-4dcf-a502-50056aaf9c50',
    clientToken: 'pubc34644e14be4db45d26434f51e765e0d',
    site: 'datadoghq.eu',
    service: 'fpay-checkout',
    env: ENV.stage,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK,
  });
  datadogRum.startSessionReplayRecording();
};

import { getStage, Stage } from '@checkout/shared/src/env';

type EnvVars = {
  cacheTimeInMinutes: number;
  casClientId: string;
  casUrl: string;
  checkoutApiUrl: string;
  ddsUrl: string;
  secureCookies: boolean;
  useReduxDevTools: boolean;
  useSentry: boolean;
};

// This allows treeshaking to omit unused env files
// eslint-disable-next-line @typescript-eslint/no-var-requires
const rawEnvVars: { [key in keyof EnvVars]: string } = require(`../env/${process.env.STAGE ?? 'local'}.env.json`);

export const ENV: EnvVars & { stage: Stage } = {
  ...rawEnvVars,
  cacheTimeInMinutes: Number(rawEnvVars.cacheTimeInMinutes),
  secureCookies: rawEnvVars.secureCookies === 'true',
  useReduxDevTools: rawEnvVars.useReduxDevTools === 'true',
  useSentry: rawEnvVars.useSentry === 'true',
  stage: getStage(),
};
